import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/qi6ke55h7GI">
    <SEO title="Never too Late to Change - Living the Dream" />
  </Layout>
)

export default SermonPost
